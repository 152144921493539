<template>
  <div class="confirm-email">
    <success-validate-account
      v-if="showSuccess"
      @emitCloseSuccess="emitCloseSuccess"
    />
    <my-custom-loader v-if="showLoader" />
  </div>
</template>

<script>
import SuccessValidateAccount from '../components/helper/rema-components/success-validate-account'
import MyCustomLoader from '../components/helper/rema-components/my-custom-loader'
import { firebaseDb } from '../main'
import { firebaseRef } from '../rema-function/firebaseRef'
export default {
  name: 'Confirm',
  components: { MyCustomLoader, SuccessValidateAccount },
  data () {
    return {
      id: this.$route.params.id,
      showSuccess: false,
      showLoader: true
    }
  },
  created () {
    let vm = this
    firebaseDb.ref(firebaseRef.users).child(this.id).child('adsInfos').child('isValided')
      .set(true)
      .then(() => {
        vm.showSuccess = true
        vm.showLoader = false
      })
      .catch((err) => {
        alert(err.toString())
      })
  },
  methods: {
    emitCloseSuccess () {
      this.$router.push('/dashboard')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/sass/variables";
</style>
